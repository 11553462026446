

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ArrowLeftIcon, ThumbsUpIcon, PlusIcon } from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";
import PhotoViewer from "../components/assets-detail/Photo-Viewer.vue";
import AssetNewSite from "../components/new-asset/Asset-NewSite.vue";
import AssetNewLocation from "../components/new-asset/Asset-NewLocation.vue";

@Component({
  components: {
    ArrowLeftIcon,
    ThumbsUpIcon,
    PlusIcon,
    Loader,
    NoData,
    PhotoViewer,
    AssetNewSite,
    AssetNewLocation,
  },
})
export default class NewAsset extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  isEditModeActive = false;
  basicDetailData = "";
  showNoData = false;
  noDataType = "Error";
  isDataLoading = false;

  // Modal
  // New-Site
  showNewSiteModal = false;
  onCloseNewSiteModal() {
    this.showNewSiteModal = false;
  }
  onOpenNewSiteModal() {
    this.showNewSiteModal = true;
  }

  onAssetSiteAddedSuccess() {
    this.fetchAllAssetSites();
  }

  site = "";
  allSites: any = [];
  siteError = "";

  async fetchAllAssetSites() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/site`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.allSites = await response.json();
        if (this.allSites.length > 0) {
          this.site = this.allSites[0].id;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  allOperators: any = [];
  async fetchAllOperator() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.allOperators = await response.json();
        if (this.allOperators.length > 0) {
          setTimeout(() => {
            this.op_name = this.allOperators[0].name;
          }, 10);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  // New location
  showNewLocationModal = false;
  onCloseNewLocationModal() {
    this.showNewLocationModal = false;
  }
  onOpenNewLocationModal() {
    this.showNewLocationModal = true;
  }

  onAssetLocationAddedSuccess() {
    this.fetchAllAssetLocations();
  }

  location = "";
  allLocations: any = [];
  locationError = "";

  async fetchAllAssetLocations() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/location`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        this.allLocations = await response.json();
        if (this.allLocations.length > 0) {
          this.location = this.allLocations[0].id;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Only requied when editing
  id = "";

  tag_id = "";
  tag_idError = "";

  serial_no = "";
  serial_noError = "";

  brand = "";
  brandError = "";

  model = "";
  modelError = "";

  device_hash = "";
  device_hashError = "";

  category = "";
  categoryError = "";

  yom = "";
  yomError = "";

  description = "";
  descriptionError = "";

  op_name = "";
  op_nameError = "";

  op_accomodation_space = "";
  op_accomodation_spaceError = "";

  op_rent_amount = "";
  op_rent_amountError = "";

  sales_person = "";
  sales_personError = "";

  customer = "";
  customerError = "";

  rfid = "";
  rfidError = "";

  // rfid_deviceHash_error = "";

  used_new = "used";
  used_newError = "";

  ansi_ce = "ansi";
  ansi_ceError = "";

  diesel_battery = "diesel";
  diesel_batteryError = "";

  engine_sr_no = "";
  engine_sr_noError = "";

  twd_fwd = "2wd";
  twd_fwdError = "";

  accessories = "";
  accessoriesError = "";

  tires = "airfilled";
  tiresError = "";

  machine_ownership_ship_type = "rental";
  machine_ownership_ship_typeError = "";

  po_no_and_copy_placed_on_oem = "";
  po_no_and_copy_placed_on_oemError = "";

  po_date = "";
  po_dateError = "";

  invoice_no = "";
  invoice_noError = "";

  invoice_date = "";
  invoice_dateError = "";

  payment_terms = "";
  payment_termsError = "";

  payment_remitted_to_the_oem = "";
  payment_remitted_to_the_oemError = "";

  date_of_payment_remitted_to_the_oem = "";
  date_of_payment_remitted_to_the_oemError = "";

  exchange_rate_of_remittence = "";
  exchange_rate_of_remittenceError = "";

  date_of_custom_duty_payment = "";
  date_of_custom_duty_paymentError = "";

  ex_works_price: any = 0;
  exworkspriceError = "";

  cif_charges: any = 0;
  cif_chargesError = "";

  total_cost: any = 0;
  total_costError = "";

  boe_no = "";
  boe_noError = "";

  custom_duty_value = "";
  custom_duty_valueError = "";

  gst_amount = "";
  gst_amountError = "";

  ex_rate_as_per_boe = "";
  ex_rate_as_per_boeError = "";

  clearing_charges = "";
  clearing_chargesError = "";

  cha_charges = "";
  cha_chargesError = "";

  transportation_charges_upto_yard = "";
  transportation_charges_upto_yardError = "";

  country_port_of_dispatch = "";
  country_port_of_dispatchError = "";

  port_of_clearance = "";
  port_of_clearanceError = "";

  under_which_gst_machine_cleared = "";
  under_which_gst_machine_clearedError = "";

  insurance_cost = "";
  insurance_costError = "";

  insurer = "";
  insurerError = "";

  period_of_insurance = "";
  period_of_insuranceError = "";

  renewal_date = "";
  renewal_dateError = "";

  total_landed_cost = "";
  total_landed_costError = "";

  total_landed_cost_gst = "";
  total_landed_cost_gstError = "";

  isLoading = false;
  showCommercialFeilds = false;

  toggleCommercialFeilds() {
    this.showCommercialFeilds = !this.showCommercialFeilds;
  }

  $refs!: {
    tag_id: HTMLInputElement;
    fileInput: HTMLInputElement;
  };
  mounted() {
    this.fetchAllOperator();
    this.fetchAllAssetSites();
    this.fetchAllAssetLocations();

    const id = this.$router.currentRoute.params.id || null;
    if (id !== null) {
      this.isEditModeActive = true;
      this.isDataLoading = true;
      this.fetchAssetWithId(id);
    } else {
      this.basicDetailData = "true";
      setTimeout(() => {
        this.$refs.tag_id.focus();
      }, 10);
    }
  }

  @Watch("tag_id")
  watchtag_id() {
    this.tag_idError = "";
  }
  @Watch("serial_no")
  watchserial_no() {
    this.serial_noError = "";
  }
  @Watch("brand")
  watchbrand() {
    this.brandError = "";
  }
  @Watch("model")
  watchmodel() {
    this.modelError = "";
  }
  @Watch("category")
  watchcategory() {
    this.categoryError = "";
  }
  @Watch("yom")
  watchyom() {
    this.yomError = "";
  }
  @Watch("description")
  watchdescription() {
    this.descriptionError = "";
  }
  @Watch("op_name")
  watchop_name() {
    this.op_nameError = "";
  }
  @Watch("op_accomodation_space")
  watchop_accomodation_space() {
    this.op_accomodation_spaceError = "";
  }
  @Watch("op_rent_amount")
  watchop_rent_amount() {
    this.op_rent_amountError = "";
  }
  @Watch("sales_person")
  watchsales_person() {
    this.sales_personError = "";
  }
  @Watch("customer")
  watchcustomer() {
    this.customerError = "";
  }
  @Watch("site")
  watchsite() {
    this.siteError = "";
  }
  @Watch("location")
  watchlocation() {
    this.locationError = "";
  }
  @Watch("rfid")
  watchrfid() {
    this.rfidError = "";
  }
  @Watch("device_hash")
  watchdevice_hash() {
    this.device_hashError = "";
  }
  @Watch("ex_works_price") onwatchex_works_price() {
    this.total_cost = Number(this.ex_works_price) + Number(this.cif_charges);
  }
  @Watch("cif_charges") onwatchcif_charges() {
    this.total_cost = Number(this.ex_works_price) + Number(this.cif_charges);
  }

  reloadData() {
    this.showNoData = false;
    this.isDataLoading = true;
    const id = this.$router.currentRoute.params.id || null;
    if (id === null) {
      return;
    }
    this.fetchAssetWithId(id);
  }

  async fetchAssetWithId(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
        // Auto filling value for easy editing
        this.id = data.id;
        this.tag_id = data.tag_id;
        this.serial_no = data.serial_no;
        this.brand = data.brand;
        this.model = data.model;
        this.device_hash = data.device_hash;
        this.rfid = data.rfid;
        this.category = data.category;
        this.yom = data.yom;
        this.description = data.description;
        this.op_name = data.op_name;
        this.op_accomodation_space = data.op_accomodation_space;
        this.op_rent_amount = data.op_rent_amount;
        this.sales_person = data.sales_person;
        this.customer = data.customer;
        this.site = data.site_id;
        this.location = data.location_id;

        this.used_new = data.used_new;
        this.ansi_ce = data.ansi_ce;
        this.diesel_battery = data.diesel_battery;
        this.engine_sr_no = data.engine_sr_no;
        this.twd_fwd = data.twd_fwd;
        this.accessories = data.accessories;
        this.tires = data.tires;
        this.machine_ownership_ship_type = data.machine_ownership_ship_type;
        this.po_no_and_copy_placed_on_oem = data.po_no_and_copy_placed_on_oem;
        this.po_date = data.po_date;
        this.invoice_no = data.invoice_no;
        this.invoice_date = data.invoice_date;
        this.payment_terms = data.payment_terms;
        this.payment_remitted_to_the_oem = data.payment_remitted_to_the_oem;
        this.date_of_payment_remitted_to_the_oem =
          data.date_of_payment_remitted_to_the_oem;
        this.exchange_rate_of_remittence = data.exchange_rate_of_remittence;
        this.date_of_custom_duty_payment = data.date_of_custom_duty_payment;
        this.ex_works_price = data.ex_works_price;
        this.cif_charges = data.cif_charges;
        this.total_cost = data.total_cost;
        this.boe_no = data.boe_no;
        this.custom_duty_value = data.custom_duty_value;
        this.gst_amount = data.gst_amount;
        this.ex_rate_as_per_boe = data.ex_rate_as_per_boe;
        this.clearing_charges = data.clearing_charges;
        this.cha_charges = data.cha_charges;
        this.transportation_charges_upto_yard =
          data.transportation_charges_upto_yard;
        this.country_port_of_dispatch = data.country_port_of_dispatch;
        this.port_of_clearance = data.port_of_clearance;
        this.under_which_gst_machine_cleared =
          data.under_which_gst_machine_cleared;
        this.insurance_cost = data.insurance_cost;
        this.insurer = data.insurer;
        this.period_of_insurance = data.period_of_insurance;
        this.renewal_date = data.renewal_date;
        this.total_landed_cost = data.total_landed_cost;
        this.total_landed_cost_gst = data.total_landed_cost_gst;

        setTimeout(() => {
          this.$refs.tag_id.focus();
        }, 10);
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      console.log(e);
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isDataLoading = false;
    }
  }

  async onSubmitForm() {
    try {
      this.isLoading = true;
      const data = {
        tag_id: this.tag_id,
        serial_no: this.serial_no,
        brand: this.brand,
        model: this.model,
        device_hash: this.device_hash,
        rfid: this.rfid,
        category: this.category,
        yom: this.yom,
        description: this.description,
        op_name: this.op_name,
        op_accomodation_space: this.op_accomodation_space,
        op_rent_amount: this.op_rent_amount,
        sales_person: this.sales_person,
        customer: this.customer,
        site_id: this.site,
        location_id: this.location,

        used_new: this.used_new,
        ansi_ce: this.ansi_ce,
        diesel_battery: this.diesel_battery,
        engine_sr_no: this.engine_sr_no,
        twd_fwd: this.twd_fwd,
        accessories: this.accessories,
        tires: this.tires,
        machine_ownership_ship_type: this.machine_ownership_ship_type,
        po_no_and_copy_placed_on_oem: this.po_no_and_copy_placed_on_oem,
        po_date: this.po_date,
        invoice_no: this.invoice_no,
        invoice_date: this.invoice_date,
        payment_terms: this.payment_terms,
        payment_remitted_to_the_oem: this.payment_remitted_to_the_oem,
        date_of_payment_remitted_to_the_oem: this
          .date_of_payment_remitted_to_the_oem,
        exchange_rate_of_remittence: this.exchange_rate_of_remittence,
        date_of_custom_duty_payment: this.date_of_custom_duty_payment,
        ex_works_price: this.ex_works_price,
        cif_charges: this.cif_charges,
        total_cost: this.total_cost + "",
        boe_no: this.boe_no,
        custom_duty_value: this.custom_duty_value,
        gst_amount: this.gst_amount,
        ex_rate_as_per_boe: this.ex_rate_as_per_boe,
        clearing_charges: this.clearing_charges,
        cha_charges: this.cha_charges,
        transportation_charges_upto_yard: this.transportation_charges_upto_yard,
        country_port_of_dispatch: this.country_port_of_dispatch,
        port_of_clearance: this.port_of_clearance,
        under_which_gst_machine_cleared: this.under_which_gst_machine_cleared,
        insurance_cost: this.insurance_cost,
        insurer: this.insurer,
        period_of_insurance: this.period_of_insurance,
        renewal_date: this.renewal_date,
        total_landed_cost: this.total_landed_cost,
        total_landed_cost_gst: this.total_landed_cost_gst,
      };
      const formData = new FormData();
      formData.append("tag_id", this.tag_id);
      formData.append("serial_no", this.serial_no);
      formData.append("brand", this.brand);
      formData.append("model", this.model);
      formData.append("device_hash", this.device_hash);
      formData.append("rfid", this.rfid);
      formData.append("category", this.category);
      formData.append("yom", this.yom);
      formData.append("description", this.description);
      formData.append("op_name", this.op_name);
      formData.append("op_accomodation_space", this.op_accomodation_space);
      formData.append("op_rent_amount", this.op_rent_amount);
      formData.append("sales_person", this.sales_person);
      formData.append("customer", this.customer);
      formData.append("site_id", this.site);
      formData.append("location_id", this.location);
      formData.append("file", this.picture);

      formData.append("used_new", this.used_new);
      formData.append("ansi_ce", this.ansi_ce);
      formData.append("diesel_battery", this.diesel_battery);
      formData.append("engine_sr_no", this.engine_sr_no);
      formData.append("twd_fwd", this.twd_fwd);
      formData.append("accessories", this.accessories);
      formData.append("tires", this.tires);
      formData.append(
        "machine_ownership_ship_type",
        this.machine_ownership_ship_type
      );
      formData.append(
        "po_no_and_copy_placed_on_oem",
        this.po_no_and_copy_placed_on_oem
      );
      formData.append("po_date", this.po_date);
      formData.append("invoice_no", this.invoice_no);
      formData.append("invoice_date", this.invoice_date);
      formData.append("payment_terms", this.payment_terms);
      formData.append(
        "payment_remitted_to_the_oem",
        this.payment_remitted_to_the_oem
      );
      formData.append(
        "date_of_payment_remitted_to_the_oem",
        this.date_of_payment_remitted_to_the_oem
      );
      formData.append(
        "exchange_rate_of_remittence",
        this.exchange_rate_of_remittence
      );
      formData.append(
        "date_of_custom_duty_payment",
        this.date_of_custom_duty_payment
      );
      formData.append("ex_works_price", this.ex_works_price);
      formData.append("cif_charges", this.cif_charges);
      formData.append("total_cost", this.total_cost);
      formData.append("boe_no", this.boe_no);
      formData.append("custom_duty_value", this.custom_duty_value);
      formData.append("gst_amount", this.gst_amount);
      formData.append("ex_rate_as_per_boe", this.ex_rate_as_per_boe);
      formData.append("clearing_charges", this.clearing_charges);
      formData.append("cha_charges", this.cha_charges);
      formData.append(
        "transportation_charges_upto_yard",
        this.transportation_charges_upto_yard
      );
      formData.append(
        "country_port_of_dispatch",
        this.country_port_of_dispatch
      );
      formData.append("port_of_clearance", this.port_of_clearance);
      formData.append(
        "under_which_gst_machine_cleared",
        this.under_which_gst_machine_cleared
      );
      formData.append("insurance_cost", this.insurance_cost);
      formData.append("insurer", this.insurer);
      formData.append("period_of_insurance", this.period_of_insurance);
      formData.append("renewal_date", this.renewal_date);
      formData.append("total_landed_cost", this.total_landed_cost);
      formData.append("total_landed_cost_gst", this.total_landed_cost_gst);

      let response;
      if (this.isEditModeActive) {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/${this.id}`,
          {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );
      } else {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset`,
          {
            method: "POST",
            credentials: "include",
            body: formData,
          }
        );
      }

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Success!",
          text: this.isEditModeActive
            ? "Asset Edited Successfully"
            : "Asset added successfully",
          type: "Success",
        });
        this.$router.replace({ path: "/asset-tracker/assets" });
      } else if (response.status == 400) {
        const error = await response.json();
        for (const i in error) {
          switch (error[i].path[0]) {
            case "tag_id":
              this.tag_idError = error[i].message;
              break;
            case "serial_no":
              this.serial_noError = error[i].message;
              break;
            case "brand":
              this.brandError = error[i].message;
              break;
            case "model":
              this.modelError = error[i].message;
              break;
            case "category":
              this.categoryError = error[i].message;
              break;
            case "yom":
              this.yomError = error[i].message;
              break;
            case "description":
              this.descriptionError = error[i].message;
              break;
            case "op_name":
              this.op_nameError = error[i].message;
              break;
            case "op_accomodation_space":
              this.op_accomodation_spaceError = error[i].message;
              break;
            case "op_rent_amount":
              this.op_rent_amountError = error[i].message;
              break;
            case "sales_person":
              this.sales_personError = error[i].message;
              break;
            case "customer":
              this.customerError = error[i].message;
              break;
            case "site_id":
              this.siteError = error[i].message;
              break;
            case "location_id":
              this.locationError = error[i].message;
              break;
            case "file":
              this.pictureError = error[i].message;
              break;
            case "device_hash":
              this.device_hashError = error[i].message;
              break;
            case "rfid":
              this.rfidError = error[i].message;
              break;
            case "rfid and device hash":
              this.device_hashError = error[i].message;
              this.rfidError = error[i].message;
              break;
            default:
              break;
          }
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  picture: any = null;
  pictureError = "";
  photoList: Array<{
    fileName: string;
    pictureSrc: any;
  }> = [];
  appPictureUploaded(e: any) {
    this.pictureError = "";
    if (e.target.files.length > 0) {
      this.picture = e.target.files[0];
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        const reader = new FileReader();
        reader.onload = (result) => {
          const pictureSrc = result.target ? result.target.result : "";
          if (this.photoList.length > 0) {
            this.photoList.pop();
          }
          this.photoList.push({
            fileName: e.target.files[0].name,
            pictureSrc: pictureSrc,
          });
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        if (this.photoList.length > 0) {
          this.photoList.pop();
        }
      }
    } else {
      this.picture = null;
    }
  }

  onAddPhoto() {
    this.$refs.fileInput.click();
  }

  onRemovePhoto(index: number) {
    this.photoList = this.photoList.filter((e, i) => i !== index);
  }
}
